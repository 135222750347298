import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`オンラインイベントとして開催する「IODD2020 OSAKA」の成果をまとめるページです． `}</p>
    <h4>{`ご案内`}</h4>
    <p>{`2020年3月7日は世界各地でオープンデータに関する活動を行う
`}<strong parentName="p">{`インターナショナル・オープンデータ・デイ（IODD）`}</strong>{`が開催されます．
大阪でも同日に，
`}<a parentName="p" {...{
        "href": "https://www.facebook.com/events/191742092221735/"
      }}>{`インターナショナルオープンデータデイ大阪2020`}</a>{`
の開催を予定しておりますが，新型コロナウイルス対策が必要とされている状況を考慮して，イベントの開催は中止といたしました．
その代わりとして，今年は以下のような要領の「オンラインイベント」として，行いたいと思います．本企画にご協力いただける方は，気軽にご参加ください．`}</p>
    <h5>{`テーマ：大阪・関西のオープンデータの“いま”を集めよう！`}</h5>
    <p>{`大阪・関西でもオープンデータに関する取組が，各地でたくさん行われるになりました．しかし，どのような取組みが行われているかの全体を把握することはなかなか難しい状態でもあります．そこで，大阪・関西におけるオープンデータに関わる情報を集め，「リンク集」の形でまとめてみたいと思います．`}</p>
    <h5>{`開催期間`}</h5>
    <p>{`2020年3月7日(土)-17日(火)`}</p>
    <h5>{`参加方法`}</h5>
    <p>{`開催期間中に，下記の情報をご提供ください．ご提供いただいた情報を，このWebサイトに「リンク集」としてまとめます．`}</p>
    <h5>{`ご提供いただく情報`}</h5>
    <p>{`大阪・関西におけるオープンデータに関わる情報であれば，何でも結構です．`}<br parentName="p"></br>{`
`}{`例）これまで開催されたイベント，オープンデータが公開されているサイト，オープンデータに関わる資料・講演スライド・記事，など`}</p>
    <h5>{`提供方法`}</h5>
    <p><strong parentName="p">{`(1)情報のタイトル`}</strong>{`，`}<strong parentName="p">{`(2)リンク先のURL`}</strong>{`，`}<strong parentName="p">{`(3)簡単な説明（省略可）`}</strong>{`を，以下のいずれかの方法でお送り下さい．`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.facebook.com/events/191742092221735/"
        }}>{`Facebookのイベントページ`}</a>{`に投稿する`}</li>
      <li parentName="ol"><strong parentName="li">{`info@lodc.jp`}</strong>{`(LODチャレンジ関西支部)宛にメールで送付する．`}</li>
      <li parentName="ol">{`このサイトを管理しているGitHubレポジトリの`}<a parentName="li" {...{
          "href": "https://github.com/KnowledgeGraphJapan/iodd2020osaka/issues"
        }}>{`https://github.com/KnowledgeGraphJapan/iodd2020osaka/issues`}</a>{`に書き込む． `}</li>
    </ol>
    <p>{`※ご提供いただいた情報は，`}<a parentName="p" {...{
        "href": "https://creativecommons.org/publicdomain/mark/1.0/deed.ja"
      }}>{`パブリックドメイン(CC0)`}</a>{`のオープンデータとして公開しますので，ご了承ください．`}</p>
    <h4>{`主催者/お問い合わせ`}</h4>
    <p>{`このオンラインイベントは，LODチャレンジ関西支部にて運営しています．
お問い合わせは，`}<strong parentName="p">{`info@lodc.jp`}</strong>{`(LODチャレンジ関西支部・古崎)までお願いします．`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      