import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title text="リンク" mdxType="Title" />
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.facebook.com/events/191742092221735/"
        }}>{`Facebookイベントページ`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/KnowledgeGraphJapan/iodd2020osaka/issues"
        }}>{`イベント用 GitHub Issueページ`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://opendataday.org/ja/"
        }}>{`インターナショナル・オープンデータ・デイ`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://lodc.jp"
        }}>{`Linked Open DataチャレンジJapan`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://wp.lodosaka.jp/"
        }}>{`LODチャレンジ関西支部`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      